import React from "react";
import "./styles.css";
import { motion, useDragControls } from "framer-motion";

export default function App() {
  const constraintsRef = React.useRef(null);
  const dragControls = useDragControls();

  const blue = "#05f";
  const purple = "#40f";
  const magenta = "#70f";

  // Stars in the background
  let stars = [];
  for (let i = 1; i < 20; i++) {
    stars.push(
      <motion.div
        className="star"
        style={{
          left: `${getRandom(-20, 120)}%`
        }}
        animate={{
          top: ["-40%", "110%", "-40%", "110%", "-40%", "110%", "-40%", "110%"]
        }}
        key={i}
        transition={{
          duration: 60,
          delay: getRandom(0, 40),
          times: [0, 0.25, 0.25, 0.5, 0.5, 0.75, 0.75, 1],
          repeat: Infinity
        }}
      >
        ✨
      </motion.div>
    );
  }

  // Obstacles to dodge
  let obstacles = ["🛰️", "🛸", "🚀", "🕳", "🐬", "🚘"];

  let animatedObstacles = [];
  for (let i = 0; i < 6; i++) {
    animatedObstacles.push(
      <motion.div
        className="obstacle"
        animate={{
          left: [
            `${getRandom(0, 100)}%`,
            `${getRandom(-20, 120)}%`,
            `${getRandom(0, 100)}%`,
            `${getRandom(-20, 120)}%`,
            `${getRandom(0, 100)}%`,
            `${getRandom(-20, 120)}%`,
            `${getRandom(0, 100)}%`,
            `${getRandom(-20, 120)}%`
          ],
          top: ["-40%", "110%", "-40%", "110%", "-40%", "110%", "-40%", "110%"],
          rotate: [
            0,
            getRandom(-180, 0),
            0,
            getRandom(180, 0),
            0,
            getRandom(-180, 0),
            0,
            getRandom(180, 0)
          ]
        }}
        key={i}
        transition={{
          duration: getRandom(15, 20),
          delay: i,
          ease: "linear",
          times: [0, 0.25, 0.25, 0.5, 0.5, 0.75, 0.75, 1],
          repeat: Infinity
        }}
      >
        {obstacles[i]}
      </motion.div>
    );
  }

  return (
    <>
      <div className="space" ref={constraintsRef}>
        <motion.div
          className="background"
          animate={{
            background: [
              // Only the color stop percentages are animated
              `linear-gradient(${magenta} -200%, ${purple} -150%, ${blue} -100%, ${purple} -50%, ${magenta} 0%, ${purple} 50%, ${blue} 100%)`,
              `linear-gradient(${magenta} -150%, ${purple} -100%, ${blue} -50%, ${purple} 0%, ${magenta} 50%, ${purple} 100%, ${blue} 150%)`,
              `linear-gradient(${magenta} -100%, ${purple} -50%, ${blue} 0%, ${purple} 50%, ${magenta} 100%, ${purple} 150%, ${blue} 200%)`,
              `linear-gradient(${magenta} -50%, ${purple} 0%, ${blue} 50%, ${purple} 100%, ${magenta} 150%, ${purple} 200%, ${blue} 250%)`,
              `linear-gradient(${magenta} 0%, ${purple} 50%, ${blue} 100%, ${purple} 150%, ${magenta} 200%, ${purple} 250%, ${blue} 300%)`
            ]
          }}
          transition={{
            duration: 10,
            ease: "linear",
            repeat: Infinity
          }}
        />
        {stars}
        {animatedObstacles}
        <motion.div
          className="invader"
          drag="x"
          dragConstraints={constraintsRef}
          dragControls={dragControls}
        >
          👾
        </motion.div>
      </div>
      <div
        className="trackpad"
        onPointerDown={(event) => dragControls.start(event)}
      />
    </>
  );
}

function getRandom(min, max) {
  return Math.random() * (max - min) + min;
}
