import React from "react";
import ReactDOM from "react-dom";
import { Refresh } from "./Refresh";
import App from "./App";
import "./styles.css";

const Wrapper = () => {
  const [count, setCount] = React.useState(0);
  return (
    <>
      <App key={count} />
      <Refresh onClick={() => setCount(count + 1)} />
    </>
  );
};

ReactDOM.render(<Wrapper />, document.getElementById("root"));
